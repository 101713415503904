body.is-neu-overlay-open
{
	position: fixed;
	width: 100%;
	height: 100%;
	overflow: hidden;
}
.neu-overlay
{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	z-index: -1;
	-webkit-overflow-scrolling: touch;
}
.neu-overlay.is-neu-overlay-open
{
	opacity: 1;
	z-index: 1;
	overflow-y: auto;
	overflow-x: hidden;
}
.neu-modal-wrap
{
	position: relative;
	width: 100%;
	min-height: 100%;
}
.neu-modal
{
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	box-sizing: border-box;
}
.neu-modal_width-full
{
	padding: 0!important;
}
.neu-modal__btns
{
	display: flex;
	justify-content: center;
}
.neu-modal__btn
{
	flex: 1; /* IE10+ */
	display: inline-block; /* IE9: And need set the custom width */
}
.neu-overlay__iframe
{
	width: 100%;
	height: 100%;
}
@media (max-width: 767px)
{
	.neu-modal_width-full
	{
		width: 100%!important;
	}
}
/* I suggest this part does not custom, you can only change the background color */
.neu-close,
.neu-close::before,
.neu-close::after
{
	height: 2px;
	width: 20px;
	background: #333;
	border-radius: 1px;
	cursor: pointer;
	display: inline-block;
	transition: 0.2s ease;
}
.neu-close
{
	position: absolute;
	right: 6px;
	top: 6px;
	height: 20px;
	width: 20px;
	background: rgba(0,0,0,0);
}
.neu-close::before
{
	position: absolute;
	top: 10px;
	left: 0;
	content: "";
}
.neu-close::after
{
	position: absolute;
	bottom: 10px;
	left: 0;
	content: "";
}
.is-neu-overlay-open .neu-close
{
	background-color: rgba(0,0,0,0);
}
.is-neu-overlay-open .neu-close::before
{
	transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
	-o-transform: rotate(45deg);
}
.is-neu-overlay-open .neu-close::after
{
	transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	top: 10px;
}
/* Default Theme Start */
.neu-overlay_theme
{
	font-size: 14px;
	font-family: arial, sans-serif;
	color: #333;
	line-height: 18px;
	background: rgba(0, 0, 0, 0.6);
	transition: all 0.2s;
}
.neu-modal_theme
{
	background-color: rgba(255,255,255,1);
	padding: 30px;
	text-align: center;
}
.neu-modal_width-default
{
	min-width: 320px;
}
.neu-modal__title
{
	font-size: 16px;
	margin-bottom: 5px;
}
.neu-modal__btn_theme
{
	box-sizing: border-box;
	height: 40px;
	line-height: 40px;
	margin: 20px 6px 0;
	font-size: 16px;
	background-color: rgba(27,72,224,1);
	color: #fff;
	text-decoration: none;
	transition: all 0.2s;
}
.neu-modal__btn_no
{
	background-color: rgba(242,242,242,1);
	color: #ccc;
}
.neu-modal__btn:hover
{
	background-color: rgba(27,72,224,.8);
}
.neu-modal__btn_no:hover
{
	background-color: rgba(242,242,242,.8);
}
/* Default Theme End */